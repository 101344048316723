import { useState, useEffect  } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Swal from "sweetalert2";
import {Link} from "react-router-dom";
import axios from "axios";

const Home = () => {

    const navigate = useNavigate();
    const [bids, setbids] = useState([]);
    const [suggestions, setsuggestions] = useState([]);

    const [openSession, setopenSession] = useState("activeButton");
    const [closeSession, setcloseSession] = useState("inactiveButton");
    const [display, setdisplay] = useState("");
    const [session, setSession] = useState("OPEN"); // Initialize with a default value


    const [showLoading, setshowLoading] = useState("none");
    const [dialogShow, setdialogShow] = useState("none");
    const [dialogShowSuccess, setdialogShowSuccess] = useState("none");

    let showDialog = ()=>{
        setdialogShow("flex")
    }
    
    let hideDialog = ()=>{
        setdialogShow("none")
    }
    const [totalAmount, settotalAmount] = useState("");
    
    const [totalBids, settotalBids] = useState(0);

        const [cycles, setCycles] = useState({
          '00': ['100', '200', '300', '400', '500', '600', '700', '800', '900', '000'],
          '10': ['100', '110', '120', '130', '140', '150', '160', '170', '180', '190'],
          '11': ['110', '111', '112', '113', '114', '115', '116', '117', '118', '119'],
          '12': ['112', '120', '122', '123', '124', '125', '126', '127', '128', '129'],
          '13': ['113', '123', '130', '133', '134', '135', '136', '137', '138', '139'],
          '14': ['114', '124', '134', '140', '144', '145', '146', '147', '148', '149'],
          '15': ['115', '125', '135', '145', '150', '155', '156', '157', '158', '159'],
          '16': ['116', '126', '136', '146', '156', '160', '166', '167', '168', '169'],
          '17': ['117', '127', '137', '147', '157', '167', '170', '177', '178', '179'],
          '18': ['118', '128', '138', '148', '158', '168', '178', '180', '188', '189'],
          '19': ['119', '129', '139', '149', '159', '169', '179', '189', '190', '199'],
          '20': ['120', '200', '220', '230', '240', '250', '260', '270', '280', '290'],
          '22': ['122', '220', '223', '224', '225', '226', '227', '228', '229', '222'],
          '23': ['123', '230', '233', '234', '235', '236', '237', '238', '239', '223'],
          '24': ['124', '240', '244', '245', '246', '247', '248', '249', '224', '234'],
          '25': ['125', '250', '255', '256', '257', '258', '259', '225', '235', '245'],
          '26': ['126', '260', '266', '267', '268', '269', '226', '236', '246', '256'],
          '27': ['127', '270', '277', '278', '279', '227', '237', '247', '257', '267'],
          '28': ['128', '280', '288', '289', '228', '238', '248', '258', '268', '278'],
          '29': ['129', '290', '299', '229', '239', '249', '259', '269', '279', '289'],
          '30': ['130', '230', '300', '330', '340', '350', '360', '370', '380', '390'],
          '34': ['134', '234', '334', '340', '344', '345', '346', '347', '348', '349'],
          '35': ['135', '350', '355', '335', '345', '235', '356', '357', '358', '359'],
          '36': ['136', '360', '366', '336', '346', '356', '367', '368', '369', '236'],
          '37': ['137', '370', '377', '337', '347', '357', '367', '378', '379', '237'],
          '38': ['138', '380', '388', '238', '338', '348', '358', '368', '378', '389'],
          '39': ['139', '390', '399', '349', '359', '369', '379', '389', '239', '339'],
          '40': ['140', '240', '340', '400', '440', '450', '460', '470', '480', '490'],
          '44': ['144', '244', '344', '440', '449', '445', '446', '447', '448', '444'],
          '45': ['145', '245', '345', '450', '456', '457', '458', '459', '445', '455'],
          '46': ['146', '460', '446', '467', '468', '469', '246', '346', '456', '466'],
          '47': ['147', '470', '447', '478', '479', '247', '347', '457', '467', '477'],
          '48': ['148', '480', '489', '248', '348', '448', '488', '458', '468', '478'],
          '49': ['149', '490', '499', '449', '459', '469', '479', '489', '249', '349'],
          '50': ['500', '550', '150', '250', '350', '450', '560', '570', '580', '590'],
          '55': ['155', '556', '557', '558', '559', '255', '355', '455', '555', '550'],
          '56': ['156', '556', '567', '568', '569', '356', '256', '456', '560', '566'],
          '57': ['157', '257', '357', '457', '557', '578', '579', '570', '567', '577'],
          '58': ['158', '558', '568', '578', '588', '589', '580', '258', '358', '458'],
          '59': ['159', '259', '359', '459', '559', '569', '579', '589', '590', '599'],
          '60': ['600', '160', '260', '360', '460', '560', '660', '670', '680', '690'],
          '66': ['660', '667', '668', '669', '666', '166', '266', '366', '466', '566'],
          '67': ['670', '167', '267', '367', '467', '567', '667', '678', '679', '677'],
          '68': ['680', '688', '668', '678', '168', '268', '368', '468', '568', '689'],
          '69': ['690', '169', '269', '369', '469', '569', '669', '679', '689', '699'],
          '70': ['700', '170', '270', '370', '470', '570', '670', '770', '780', '790'],
          '77': ['770', '177', '277', '377', '477', '577', '677', '778', '779', '777'],
          '78': ['178', '278', '378', '478', '578', '678', '778', '788', '789', '780'],
          '79': ['179', '279', '379', '479', '579', '679', '779', '789', '799', '790'],
          '80': ['180', '280', '380', '480', '580', '680', '780', '880', '800', '890'],
          '88': ['188', '288', '388', '488', '588', '688', '788', '889', '888', '880'],
          '89': ['189', '289', '389', '489', '589', '689', '789', '889', '890', '899'],
          '90': ['900', '190', '290', '390', '490', '590', '690', '790', '890', '900'],
          '99': ['199', '299', '399', '499', '599', '699', '799', '899', '990', '999'],
        });


    let timing = ""

    let data = useLocation();

    if (data.state == null){
        window.location.href = "/";
    }

    let marketName = data.state[0].market;
    let is_open_status = data.state[0].is_open;
    let game_name = data.state[0].game;
    if (data.state[0].timing !== undefined) {
        timing = data.state[0].timing
    }



    let numbers = [];
    if (game_name === "single"){
        numbers = ["0","1","2","3","4","5","6","7","8","9"];
    } else if (game_name === "jodi"){
        numbers = ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40","41","42","43","44","45","46","47","48","49","50","51","52","53","54","55","56","57","58","59","60","61","62","63","64","65","66","67","68","69","70","71","72","73","74","75","76","77","78","79","80","81","82","83","84","85","86","87","88","89","90","91","92","93","94","95","96","97","98","99"];
        // for (var a = 0; a < 100; a++){
        //     if (a < 10){
        //         a = "0"+String.valueOf(a)
        //     }
        //     numbers.push(a)
        // }
       // setdisplay("none");
    } else if (game_name === "singlepanna"){
      //  numbers = ["128","137","146","236","245","290","380","470","489","560","560","560","560","560","560","560","560","560","560","560","560","560","560","560"]
        numbers = [
    "127", "136", "145", "190", "235", "280", "370", "389", "460", "479", "569", "578", "128", "137", "146", 
    "236", "245", "290", "380", "470", "489", "560", "579", "678", "129", "138", "147", "156", "237", "246", 
    "345", "390", "480", "570", "589", "679", "120", "139", "148", "157", "238", "247", "256", "346", "490", 
    "580", "670", "689", "130", "149", "158", "167", "239", "248", "257", "347", "356", "590", "680", "789", 
    "140", "159", "168", "230", "249", "258", "267", "348", "357", "456", "690", "780", "123", "150", "169", 
    "178", "240", "259", "268", "349", "358", "367", "457", "790", "124", "160", "278", "179", "250", "269", 
    "340", "359", "368", "458", "467", "890", "125", "134", "170", "189", "260", "279", "350", "369", "468", 
    "378", "459", "567","126", "135","180","234","270","289","360","379","450","469","478","568"
];
    }  else if (game_name === "doublepanna"){
        //  numbers = ["128","137","146","236","245","290","380","470","489","560","560","560","560","560","560","560","560","560","560","560","560","560","560","560"]
        numbers = ["118", "226", "244", "299", "334", "488", "550", "668", "677", "100", "119", "155", "227", "335", "344", "399", "588", "669", "110", "200", "228", "255", "366", "499", "660", "688", "778", "166", "229", "300", "337", "355", "445", "599", "779", "788","112", "220", "266", "338", "400", "446", "455", "699", "770", "113", "122", "177", "339", "447", "500", "799", "889", "600", "114", "277", "330", "448", "466", "556", "880", "899","115", "133", "188", "223", "377", "449", "557", "566", "700","116", "224", "233", "288", "440", "477", "558", "800", "990","117", "144", "199", "225", "388", "559", "577", "667", "900"];
    } else if (game_name === "triplepanna"){
        //  numbers = ["128","137","146","236","245","290","380","470","489","560","560","560","560","560","560","560","560","560","560","560","560","560","560","560"]
        numbers = ["000", "111", "222", "333", "444", "555", "666", "777", "888", "999"];
    }


    let getSuggest = (val)=>{
        setbid(val)
        if (val === ""){
            setsuggestions([])
            return
        }
        let tempSuggestion = []
        console.log(numbers)
        for (let a = 0; a < numbers.length; a++){
            if (numbers[a].substring(0, val.length) === val){
                tempSuggestion.push(numbers[a])
            }
        }
        setsuggestions(tempSuggestion)
    }

    let changeSession = (e)=>{
        console.log(e);
        if (e === "OPEN" && is_open_status === "1"){
            setSession("OPEN")
        } else if (e === "CLOSE"){
            setSession("CLOSE")
        }
    }

    useEffect(() => {
        // Check the condition and update the session state accordingly
        if(game_name !== "jodi"){
            if (is_open_status === "0") {
            setSession("CLOSE");
            } else {
            setSession("OPEN");
            }
        } else {
            setSession("");
        }
      }, [is_open_status]); // The effect will re-run whenever is_open_status changes



    const getTotalAmount = (bids) => {
        let totalAmount = 0;
        bids.forEach(bid => {
          totalAmount += parseInt(bid.amount);
        });
        return totalAmount;
      };

    
      
      let addBid = () => {
        if (parseInt(amount) >= localStorage.getItem('min_bet')) {

            if(cycles[bid] != null && cycles[bid] != undefined){
                for(var m=0; m < cycles[bid].length; m++){
                    let bidObj = { amount: amount, number: cycles[bid][m], session: session };
                    setbids(prevBids => {
                      const updatedBids = [...prevBids, bidObj];
                      const totalAmount = getTotalAmount(updatedBids);
                      settotalAmount(totalAmount);
                      settotalBids(updatedBids.length);
                      return updatedBids;
                    });
                }
            }
          
        } else if (parseInt(amount) < 10) {
          Swal.fire({
            toast: true,
            title: 'Oops...',
            text: "Bid Amount must be above "+localStorage.getItem("min_bet")+" INR"
          });
        } else if (!numbers.includes(parseInt(bid)) && !numbers.includes(bid)) {
          Swal.fire({
            toast: true,
            title: 'Oops...',
            text: "Enter valid bid number"
          });
        }
      };
      
      let removePeople = (e) => {
        setbids(prevBids => {
          const updatedBids = prevBids.filter(person => person !== e);
          const totalAmount = getTotalAmount(updatedBids);
          settotalAmount(totalAmount);
          settotalBids(updatedBids.length);
          return updatedBids;
        });
      };

    const [amount, setamount] = useState("");
    const [bid, setbid] = useState("");


    let setbidss = (bal)=>{
        setbid(bal)
        setsuggestions([])
    }

    let loginSubmit = () =>{

        setshowLoading("block");
        let numberString,amountString,typesString

        let numberArray = []
        let amountArray = []
        let typesArray = []

        bids.map((result) => {
            numberArray.push(result.number)
            amountArray.push(result.amount)
            typesArray.push(result.session)
        })

        numberString = numberArray.join(",")
        amountString = amountArray.join(",")
        typesString = typesArray.join(",")

        var form = new FormData();

        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        form.append("bazar", marketName);
        form.append("number", numberString);
        form.append("amount", amountString);
        form.append("types", typesString);
        form.append("game", game_name);
        if (timing !== "") {
            form.append("timing", timing);
        }
        axios
            .post(process.env.REACT_APP_URL+`/api2/bet.php`,form)
            .then((response) => {
                if (response.data.nosession){
                    setshowLoading("none");
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: "Session Expired"
                    }).then((e)=>{
                        localStorage.clear()
                        window.location.href = "/login";
                    })
                }
                if(response.data.success === "0"){
                    setshowLoading("none");
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: response.data.msg
                    })
                } else {
                    setshowLoading("none");
                    setdialogShow("none")
                    setdialogShowSuccess("flex")
                }
            })
    }


    return (<>
            <div className="RootView">
                <div className="RootWidth">

                <div style={{display:showLoading}}>
                        <div class="loader-parent">
                        <div class="loader-block">
                        <div class="loader"></div> 
                        </div>
                        </div>
                    </div>

                    <div className="loginDiv">

                        <div className="toolbar">
                            <div>
                                <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                <span style={{ textTransform: "uppercase"}}>{marketName}, Two Digit Panel</span>
                            </div>
                            <Link to={"/wallet"}>
                                <div>
                                    <img className="wallet-img" src="../images/wallet_figma.png"/>
                                    <span className="wallet-text">{localStorage.getItem("wallet")}</span>
                                </div>
                            </Link>
                        </div>



                     

                        <div className="main-game-screen">

                        { game_name !== "jodi" ?
                        <div className="game-play-box" onChange={(e) => changeSession(e)} style={{marginBottom: "10px"}}>
                                <span>
                                    Select Game Type :
                                </span>
                                <select>
                                { is_open_status !== "0" ?
                                    <option value="OPEN">OPEN</option>  : ""}
                                    <option value="CLOSE">CLOSE</option>
                                </select>
                            </div> : ""}

                            <div className="game-play-box">
                                <span>
                                    Enter Digit :
                                </span>
                                <div>
                                    <input
                                        className="edittext basicAutoComplete"
                                        data-url="../single.json"
                                        name="mobile"
                                        style={{marginBottom:"0px"}}
                                        type="number"
                                        autoComplete="off"
                                        value={bid}
                                        onChange={(e)=>{getSuggest(e.target.value)}}
                                        placeholder="Bid Digits"
                                    />
                                    <ul className="suggest">
                                        { suggestions.map((result) => { return (
                                        <li onClick={()=>{setbidss(result)}}>{result}</li>
                                        )})}
                                    </ul>
                                </div>
                            </div>


                            <div className="game-play-box">
                                <span>
                                    Enter Points :
                                </span>
                                <div>
                                    <input
                                        className="edittext"
                                        name="mobile"
                                        type="number"
                                        style={{marginTop:"10px"}}
                                        value={amount}
                                        onChange={(e)=>{setamount(e.target.value)}}
                                        placeholder="Enter Points"
                                    />

                                </div>
                            </div>

                            <div className="game-play-add">
                                <button className="button" onClick={addBid}>
                                    ADD BID
                                </button>
                            </div>

                            <div className="bet-list bottom-border">
                                    <span>
                                        Digit
                                    </span>
                                    <span>
                                       Points
                                    </span>
                                    <span>
                                        Game type
                                    </span>
                                </div>

                            { bids.map((result) => { return (
                                <div className="bet-list bet-shadow">
                                    <span>
                                        {result.number}
                                    </span>
                                    <span>
                                       {result.amount}
                                    </span>
                                    <span>
                                        {result.session} <img style={{marginLeft:"10px"}} onClick={()=>{removePeople(result)}} src="../images/ic_delete.png" />
                                    </span>
                                </div>
                            )})}


                        </div>
                    </div>

                </div>
                { totalBids !== 0 ?
                <div className="game-play-bottom">

                    <div className="game-play-bottom-first-div">
                        <div className="list-div">
                            <span>Bid</span>
                            <span>{totalBids}</span>
                        </div>
                        
                        <div className="list-div">
                            <span>Total</span>
                            <span>{totalAmount}</span>
                        </div>
                    </div>
                    
                    <div>
                    <button className="button" onClick={showDialog}>
                                Submit
                            </button>
                    </div>
                </div>
                 : ""}


<div className="review-bets-dialog" style={{display:dialogShowSuccess}}>
      <div className="dialog-content">
      
        <div className="bet-details" style={{flexDirection:"column", alignItems:"center"}}>

            <img src="../images/success_green.png" style={{height:"75px", marginBottom:"14px"}} />
            <span style={{color:"green"}}>Your Bids Placed Successfully</span>
         
         
        </div>
       
        <div className="dialog-actions">
          <button className="submit-button" onClick={()=>{window.history.go(-1)}}>OK</button>
        </div>
      </div>
    </div>

<div className="review-bets-dialog" style={{display:dialogShow}}>
      <div className="dialog-content">
        <div className="dialog-header">
          <h2>Review Bets</h2>
        </div>
        <div className="bets-table">
          <div className="table-header">
            <div>Digit</div>
            <div>Points</div>
            <div>Type</div>
          </div>
          { bids.map((result) => { return (
            <div className="bet-list bet-shadow">
                <span>
                    {result.number}
                </span>
                <span>
                    {result.amount}
                </span>
                <span>
                    {result.session}
                </span>
            </div>
        )})}
        </div>
        <div className="bet-details">
          <div>
            <span>Total Bids</span>
            <strong>{totalBids}</strong>
          </div>
          <div>
            <span>Total Bid Points</span>
            <strong>{totalAmount}</strong>
          </div>
        </div>
        <div className="balance-details">
          <div>
            <span>Point Balance Before Game Play</span>
            <strong>{localStorage.getItem("wallet")}</strong>
          </div>
          <div>
            <span>Point Balance After Game Play</span>
            <strong>{localStorage.getItem("wallet") - totalAmount}</strong>
          </div>
        </div>
        <div className="note">
          <p>Note: Bid Once Played Can Not Be Cancelled</p>
        </div>
        <div className="dialog-actions">
          <button className="cancel-button" onClick={hideDialog}>Cancel</button>
          <button className="submit-button" onClick={loginSubmit}>Submit</button>
        </div>
      </div>
    </div>


            </div>

        </>
    );
};

export default Home;
